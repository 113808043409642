import './CSS/app.css';
import { Route, Routes, Navigate } from "react-router-dom";
import HomeScreen from './homeScreen';


function App() {
  return (
    <div className="main-container">
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<HomeScreen />} />
      </Routes>
      {/* <Navbar />
      <Banner />
      <How_it_works />
      <Research />
      <WhyCrypto />
      <About />
      <Faq />
      <Footer /> */}
    </div>
  );
}

export default App;
