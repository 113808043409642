import React from 'react';
import './../CSS/about.css';
import aboutGif from './../assets/about.gif';

const About = () => {
    return (
        <div
            id="about"
            className="about_section">
            <div className="about_content">
                <h1 className="about_heading">About</h1>
                <p className="about_text">
                    Nirmaan’s mining-as-a-service product sources and aggregates the most cost-effective compute from web2 & web3 before deploying it and expertly managing it on decentralized AI networks on behalf of our users.
                </p>
                <p className="about_text">
                    By supplying GPU compute to the networks like Heurist, Bittensor, Morpheus and Ritual, users can acquire tokens at a cost that is between 60–70% of the current market price and even as low as 5–10% for emerging networks.
                </p>
            </div>
            <div className="about_background">
                <div className='blackGradient'></div>
                <div className='pinkGradientAbout'></div>
                <img src={aboutGif} alt="About background" className="about_gif" />
            </div>
        </div>
    );
};

export default About;
