import React, { useState } from 'react';
import "./../CSS/faq.css"

const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleFaq = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={toggleFaq}>
            <div className="faq-question"><span>{question}</span>   <span className={`faq-icon ${isOpen ? 'open' : ''}`}>{'▼'}</span></div>

            <div className="faq-answer">{answer}</div>
        </div>
    );
};

const Faq = () => {
    const faqs = [
        {
            question: 'How does our backend work?',
            answer: 'Our service priotizes self-custody. A user provides their wallet address in the miner deployment process and we insert this into the miner scripts we run, meaning all rewards will flow to this address, to which the user has sole access to.',
        },
        {
            question: 'What are the fees?',
            answer: 'We take no fees / take rate on the rewards earned from the miners we run on behalf of our users. The price you pay is the full price of our service, which includes a roughly 10% markup on the costs to source and run the GPUs.',
        },
        {
            question: 'Is it self custody?',
            answer: 'Yes, our service priotizes self-custody. A user provides their wallet address in the miner deployment process and we insert this into the miner scripts we run, meaning all rewards will flow to this address, to which the user has sole access to.',
        },
        {
            question: 'Where do we source compute from?',
            answer: 'We partner with every available source of compute we can find. This includes independent data centres, large cloud providers and web3 marketplaces. During the deployment process we aggregate compute, ensuring users get the most cost effective hardware.',
        },
    ];

    return (
        <div id="faq" className="faq-container">
            <h1 className="faq-title">FAQ</h1>
            {faqs.map((faq, index) => (
                <FaqItem key={index} question={faq.question} answer={faq.answer} />
            ))}
        </div>
    );
};

export default Faq;
