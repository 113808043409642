import './CSS/app.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from './Components/navbar';
import Banner from './Components/banner';
import How_it_works from './Components/howItWorks';
import Research from './Components/research';
import WhyCrypto from './Components/whyCrypto';
import About from './Components/about';
import Faq from './Components/faq';
import Footer from './Components/footer';


function HomeScreen() {
    return (
        <div className="main-container">

            <Navbar />
            <Banner />
            <How_it_works />
            <Research />
            <WhyCrypto />
            <About />
            <Faq />
            <Footer />
        </div>
    );
}

export default HomeScreen;
