import React, { useState } from "react";
import "./../CSS/navigation.css"
import logo from '../assets/Nirmaan_Logo.svg';
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
    return (
        <>
            <div

                className="navbar"
            >
                <div className="logo">
                    <Link to='/home#home' activeStyle>
                        <img src={logo} alt="Logo" className="" />
                    </Link>
                </div>
                <div className="frame_4">
                    <div className="navbar_item">
                        <Link smooth to="/home#home">
                            Home
                        </Link>
                    </div>
                    <div className="navbar_item">
                        <Link smooth to="/home#how_it_work"
                        >
                            How it works
                        </Link>
                    </div>

                    <div className="navbar_item">
                        <Link smooth to="/home#research"
                        >
                            Research
                        </Link>

                    </div>
                    <div className="navbar_item">
                        <Link smooth to="/home#about"
                        >
                            About
                        </Link>
                    </div>
                    <div className="navbar_item">
                        <Link smooth to="/home#faq"
                        >
                            FAQ
                        </Link>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Navbar;