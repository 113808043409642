import React, { Component } from 'react';
import "./../CSS/how_it_works.css";
import bgImage from './../assets/how_it_works.gif';


class How_it_works extends Component {
    //check for code bu

    render() {
        return <div
            id='how_it_work'
            className="how_it">
            <div className='bgHowItWorks'>
                <div className='blackGradient'></div>
                <div className='pinkGradient'></div>
                <img className='bgHowItWorksImage' src={bgImage} />
            </div>
            <div className="frame_3274">
                <div className="frame_3273">
                    <span className="lets_know">Let’s know</span>
                </div>
                <span className="heading_how_it_works">How it works</span>
            </div>
            <div className="frame_3257">
                <div className="stepContainer">
                    <div class="step-indicator">
                        <div class="step">
                            <div class="circle">1</div>
                            <div class="line"></div>
                            <div className="card_main">
                                <span className="card_head">Selection</span>
                                <span className="card_text">A user selects the network, the model {'&'} number of GPUs as well as the duration of mining. The user then pre-funds this GPU via stablecoins.</span>

                            </div>
                        </div>
                        <div class="step">
                            <div class="circle">2</div>
                            <div class="line"></div>
                            <div className="card_main">
                                <span className="card_head">Compute aggregator</span>
                                <span className="card_text">After the payment is confirmed, Nirmaan’s aggregation software sources the most cost effective compute from web 2 cloud providers {'&'} web 3 marketplaces.</span>
                            </div>
                        </div>
                        <div class="step">
                            <div class="circle">3</div>
                            <div className="card_main">
                                <span className="card_head">Deployment {'&'} Analytics</span>
                                <span className="card_text">Once we source the necessary computing power, our software automatically deploys the miner, enabling users to earn rewards. Wherever possible, we offer advanced analytics to our users, allowing them to track their rewards and participation on the network.</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="frame_3256">
                    <div className="card_main">
                        <span className="card_head">Selection</span>
                        <span className="card_text">A user selects the network, the model {'&'} number of GPUs as well as the duration of mining. The user then pre-funds this GPU via stablecoins.</span>

                    </div>
                    <div className="card_main">
                        <span className="card_head">Compute aggregator</span>
                        <span className="card_text">After the payment is confirmed, Nirmaan’s aggregation software sources the most cost effective compute from web 2 cloud providers {'&'} web 3 marketplaces.</span>
                    </div>

                    <div className="card_main">
                        <span className="card_head">Deployment {'&'} Analytics</span>
                        <span className="card_text">Once we source the necessary computing power, our software automatically deploys the miner, enabling users to earn rewards. Wherever possible, we offer advanced analytics to our users, allowing them to track their rewards and participation on the network.</span>
                    </div>
                </div> */}

            </div>

        </div>
    }
}

export default How_it_works;