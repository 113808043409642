import React, { Component } from 'react';
import "./../CSS/banner.css"
import AnimatedButton from './animatedButton';
import bgImg from '../assets/banner.gif';

class Banner extends Component {
    //check for code bu

    render() {
        return <div
            id='home'
            className="banner_main"
        >
            <div className="frameTextButton">
                <img className='bgImage' src={bgImg} />
                <div className="frameText">
                    <div className="frameSilverText">
                        <span className='silverText'>Decentralised AI</span>
                        <span className='silverText'>Crypto Mining</span>
                    </div>
                    <span className='subText'>Nirmaan is a white glove decentralized AI mining protocol, democratizing access to compute provision for non-technical participants. With just a few clicks, participants can deploy miners on leading crypto AI networks.</span>
                </div>
                <a href="https://app.nirmaan.ai/" target="_blank">
                    <AnimatedButton text="Launch App" />
                </a>
                {/* <div className='pixel1'></div>
                <div className='pixel2'></div>
                <div className='pixel3'></div>
                <div className='pixel4'></div>
                <div className='pixel5'></div>
                <div className='pixel6'></div> */}
            </div>
            <div className="gridDesign">
            </div>
        </div>
    }
}

export default Banner;