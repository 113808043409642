import React, { Component } from "react";
import AnimatedButton from "./animatedButton";
import "./../CSS/research.css";
import img1 from '../assets/research1.jpeg';
import img2 from '../assets/research2.jpeg';

export default class Research extends Component {
    render() {
        return (
            <div
                id="research"
                className="research"
            >
                <div className="researchHeading"> Research</div>
                <div className="researchContent">
                    <a href="https://mirror.xyz/0xC36A87666c505Fe695fc097d238725ff4d34877D/StMN0BAl-m6HHXWBU7_7QHovA6JUEsdiAvOw-PxnhCM" target="_blank">
                        <div className="researchCard">
                            <div className="researchImage">
                                <img src={img1} alt="research image" />
                            </div>
                            <div className="researchText">
                                <div className="researchHeadText">Running Heurist on Io Net</div>
                                <div className="researchSubText">In the computing landscape, a significant shift towards decentralization is taking place, moving away from traditional centralized models. This evolution is underscored by innovations such as the Io Net.</div>
                                <div className="researchColoredText">Mint</div>
                            </div>
                            <div className="researchFooter">
                                <div className="researchCollection">
                                    <div className="researchCollectionCircles">
                                        <div className="researchCollectionCircle"></div>
                                        <div className="researchCollectionCircle"></div>
                                        <div className="researchCollectionCircle"></div>
                                    </div>
                                    <div className="researchCollectionText">5 Collected</div>
                                </div>
                                <div className="researchName"><span className="researchNameText">Nirmaan</span></div>
                                <div className="researchDate"><span className="researchDateText">4th April 2024</span></div>
                            </div>
                        </div>
                    </a>
                    
                    <a href="https://mirror.xyz/0xC36A87666c505Fe695fc097d238725ff4d34877D/WUUklWhaAUwGfY-VpO3ha1gaK4CNOY7okTjsVI5Ol6s" target="_blank">
                        <div className="researchCard">
                            <div className="researchImage">
                                <img src={img2} alt="research image" />
                            </div>
                            <div className="researchText">
                                <div className="researchHeadText">Morpheus Yellowstone Compute Model</div>
                                <div className="researchSubText">Morpheus aims to enhance the development of a peer-to-peer network that includes personal, general-purpose AIs known as Smart Agents. These agents are capable of executing Smart Contracts on behalf of users. </div>
                                <div className="researchColoredText"> Mint </div>
                            </div>
                            <div className="researchFooter">
                                <div className="researchCollection">
                                    <div className="researchCollectionCircles">
                                        <div className="researchCollectionCircle"></div>
                                        <div className="researchCollectionCircle"></div>
                                        <div className="researchCollectionCircle"></div>
                                    </div>
                                    <div className="researchCollectionText">3 Collected</div>
                                </div>
                                <div className="researchName"><span className="researchNameText">Nirmaan</span></div>
                                <div className="researchDate"><span className="researchDateText">11th April 2024</span></div>
                            </div>
                        </div>
                    </a>
                </div>
                <a href="https://mirror.xyz/0xC36A87666c505Fe695fc097d238725ff4d34877D" target="_blank">
                    <AnimatedButton text="More" />
                </a>
            </div>
        );
    }
}