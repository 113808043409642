import React, { useState } from "react";
import "./../CSS/whyCrypto.css"
import Card from "./card";
import centralized_1 from './../assets/centralized_1.png'
import incentivice_ownership from './../assets/incentivice_ownership.png'
import decentralized_1 from './../assets/decentralized_1.png'


const WhyCrypto = () => {

    const cards = [
        {
            icon: centralized_1,
            title: 'Centralization',
            description: 'Data, compute & inference are centralized, aggregating within the hands of a few large monopolies.',
        },
        {
            icon: incentivice_ownership,
            title: 'Incentives & Ownership',
            description: 'Imposing the misaligned incentives of Web2 on the emerging AI industry is detrimental. In contrast, crypto supports networks and governance systems that are owned and controlled by users.',
        },
        {
            icon: decentralized_1,
            title: 'Decentralization',
            description: 'Permissionless, uncensorable networks for settlement and incentivization can foster the secure and democratic evolution of AI.',
        },
    ];
    return (
        <>

            <div className="why_crypto_main">

                <div className="why_crypto_bg"></div>

                <div className="frame_3276">
                    <span className="why_crypto_heading">Why Crypto x AI</span>
                    <span className="why_crypto_heading_text">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>

                </div>

                <div className="card_main_1">
                    <div className="card_container">
                        {cards.map((card, index) => (
                            <Card key={index} icon={card.icon} title={card.title} description={card.description} />
                        ))}
                    </div>
                </div>

                {/* <div className="card_main_1">

                    <div className="card">
                        <div className="card_image" style={{ background: centeralized_image }}></div>
                        <div className="card_heading">
                            <span>Centralization</span>
                        </div>
                        <div className="card_text_1">
                            <span >
                                Data, compute {'&'} inference are centralised, aggregating within the hands of a few large monopolies.
                            </span>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card_image" style={{ background: centeralized_image }}></div>
                        <div className="card_heading">
                            <span>Centralization</span>
                        </div>
                        <div className="card_text_1">
                            <span >
                                Data, compute {'&'} inference are centralised, aggregating within the hands of a few large monopolies.
                            </span>
                        </div>
                    </div>
                </div> */}
            </div>

        </>
    );
};

export default WhyCrypto;