import React from 'react';
import './../CSS/footer.css'
import twitterIcon from './../assets/twitter.png';
import instagramIcon from './../assets/instagram.png';
import linkedinIcon from './../assets/linkedIn.png';
import telegramIcon from './../assets/telegram.png';
import footerBackground from './../assets/footer.gif';

const Footer = () => {
    return (
        <footer className="footer-container">
            <nav className="footer-menu">
                <a href="#home">Home</a>
                <a href="#about">About</a>

                <a href="https://app.nirmaan.ai/" target="_blank">
                    Marketplace
                </a>
                <a href="https://mirror.xyz/0xC36A87666c505Fe695fc097d238725ff4d34877D" target="_blank">Research</a>
            </nav>
            <div className="footer-social">
                <a href="https://x.com/nirmaanai" target="_blank" rel="noopener noreferrer">
                    <img src={twitterIcon} alt="Twitter" />
                </a>
                <a href="https://t.co/x9cDxwiFU6" target="_blank" rel="noopener noreferrer">
                    <img src={telegramIcon} alt="Telegram" />
                </a>
                {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <img src={instagramIcon} alt="Instagram" />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinIcon} alt="LinkedIn" />
                </a> */}
            </div>
            <div className="footer-bottom">
                &copy; 2024 Nirmaan
            </div>
        </footer>
    );
};

export default Footer;
