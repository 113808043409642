import React from 'react';
import "./../CSS/whyCrypto.css" // Ensure you import the CSS file

const Card = ({ icon, title, description }) => {
    return (
        <div className="card">
            <div className="card_icon">
                <img src={icon} alt={`${title} icon`} />
            </div>
            <h3 className="card_title">{title}</h3>
            <p className="card_description">{description}</p>
        </div>
    );
};

export default Card;
