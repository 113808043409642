import React, { Component } from 'react';
import "./../CSS/animatedButton.css"

class AnimatedButton extends React.Component {
    render() {
        const {text} = this.props;
        return  <div className={`${text} button`}>
                    <div className="btn_design">
                        <span className="btn_text">{text}</span>
                    </div>
                </div>
    }
}

export default AnimatedButton;